<template>
    <v-footer color="grey lighten-4 py-5">
        <v-container>
            <div class="container py-4 py-lg-5">
                <div class="row row-cols-2 row-cols-md-4 d-flex justify-content-center">
                    <div class="col-12 col-md-6 col-lg-5 col-xl-6 col-xxl-6">
                        <div class="fw-bold d-flex align-items-center mb-2" style="color: #FF5E21;"><span
                                style="color: var(--bs-orange);">UMOJA</span></div>
                        <p class="text-muted" style="font-size: 20px;">A non-profit orgonization dedicated to assisting
                            non-English speakers by providing scholarship funds, enabling them to thrive on their
                            academic&nbsp;journeys.</p>
                    </div>
                    <div class="col-sm-6 col-md-3 col-xxl-3 text-lg-start d-flex flex-column">
                        <h3 class="fs-5 fw-bold">Services</h3>
                        <ul class="list-unstyled">
                            <li><a class="black--text" href="https://www.umojasc.com/about.html">About us</a></li>
                            <li><a class="black--text" href="https://www.umojasc.com/comming.html">Scholarships</a></li>
                            <li><a class="black--text" href="https://www.umojasc.com/jobs.html">Jobs
                                    Opportunities</a></li>
                            <li><a class="black--text"
                                    href="#/apply">Donation</a>
                            </li>
                            <li><a class="black--text" href="https://www.umojasc.com/contacts.html">Contacts</a></li>
                        </ul>
                    </div>
                    <div class="col-sm-6 col-md-3 col-xxl-3 text-lg-start d-flex flex-column">
                        <h3 class="fs-5 fw-bold">Social Media</h3>
                        <ul class="list-unstyled">
                            <li><a class="black--text" href="https://www.instagram.com/umoja_scholarship_/" target="_blank">Instagram</a>
                            </li>
                            <li><a class="black--text" href="https://www.facebook.com/GodsgiftPage?mibextid=tzlV2A"
                                    target="_blank">Facebook</a></li>
                            <li></li>
                        </ul>
                    </div>
                </div>
                <hr>
                <div class="text-muted d-flex justify-content-between align-items-center pt-3">
                    <p class="mb-0">Copyright © 2023 UMOJA</p>
                </div>
            </div>
        </v-container>
    </v-footer>
</template>

<script>
    export default {

    }
</script>

<style>

</style>